<script lang="ts" setup>
import { ref, computed, watch, provide, useAttrs } from 'vue';
import { useMedia } from '@/plugins/media';
import { useElementSize } from '@vueuse/core';
import Pager from '@/helpers/Pager';
import CardLayout from './layouts/CardLayout.vue';
import FlexLayout from './layouts/FlexLayout.vue';
import TableLayout from './layouts/TableLayout.vue';
import CommentLayout from './layouts/CommentLayout.vue';
import Dropdown from './settings/Dropdown.vue';
import { Header } from './helpers';

export interface Props
{
    loaded?: boolean;
    loading?: boolean;
    layout?: 'auto'|'table'|'flex'|'card';
    showHeader?: boolean;
    headers?: Header[];
    items: any[];
    columns?: {
        enabled?: string[],
        visible: Record<string, boolean>,
        positions: Record<string, number>
    };
    draggable?: boolean;
    pager: Pager;
    emptyLabel?: string;
    loadingLabel?: string;
    rowClick?: (item: any, i: number, e: PointerEvent) => void;
    rowClass?: (item: any, i: number) => Record<string, boolean> | string[] | string;
    customisable?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    loaded: false,
    loading: false,
    layout: 'auto',
    showHeader: true,
    headers: () => [] as any[],
    columns: () => ({visible: {}, positions: {}}),
    pager: () => new Pager(1, 20),
    emptyLabel: '[[[Brak wyników]]]',
    loadingLabel: '[[[Wczytywanie danych...]]]',
    rowClick: (item: any, i: number, e: PointerEvent) => {},
    rowClass: (item: any, i: number) => ({}),
    draggable: false,
    customisable: true
});
const emit = defineEmits<{
    (e: 'update:loaded', value: boolean): void,
    (e: 'check', value: boolean): void,
    (e: 'drag', value: any): void,
    (e: 'change'): void
}>();
const $attrs = useAttrs();

defineOptions({
    name: 'list-view-base',
    components: {
        'list-view-card-layout': CardLayout,
        'list-view-flex-layout': FlexLayout,
        'list-view-table-layout': TableLayout,
        'list-view-comment-layout': CommentLayout
    },
    inheritAttrs: false
});

const media = useMedia();

const content = ref<HTMLDivElement | null>(null);
const { width: contentWidth } = useElementSize(content);

const scrollableContent = ref({
    width: 0,
    setScrollLeft: null,
});

const scrollBar = ref<HTMLDivElement | null>(null);
const isScrollBar = computed(() => scrollableContent.value?.width > contentWidth.value);
const layout = computed(() =>
{
    if (props.layout == 'auto')
    {
        return media.mobile() ? 'flex' : 'table';
    }

    return props.layout;
});
const pager = computed(() => props.pager);
const layoutName = computed(() => `list-view-${layout.value}-layout`);
const loaded = computed({
    get: () => props.loaded,
    set: (value) => emit('update:loaded', value)
});
const headers = computed(() =>
{
    return props.headers
        .map((p, index) =>
        {
            const header = {
                ...p,
                key: p.name || `col-${index}`,
                visible: p.visible === undefined ? true : p.visible,
                checkbox: p.type == 'checkbox',
                position: p.position !== undefined ? p.position : index
            };

            header.visible = getVisibility(header);
            header.position = getPosition(header);

            if (p.disabled === undefined)
            {
                header.disabled = props.columns.enabled?.any()
                    ? !props.columns.enabled.includes(header.key)
                    : false;
            }

            if (['checkbox', 'handle', 'hierarchy'].includes(p.type))
                header.position = 0;

            if (p.type == 'buttons')
                header.position = Number.MAX_SAFE_INTEGER;

            if (['checkbox', 'handle', 'hierarchy', 'buttons'].includes(p.type))
                header.disabled = false;

            return header as Header;
        });
});

const columns = computed(() => props.columns);
const visible = computed({
    get: () => columns.value.visible,
    set: (value) => { columns.value.visible = value; }
});
const getVisibility = (header: Header): boolean =>
{
    return header.key in visible.value ? visible.value[header.key] : header.visible;
};

const positions = computed({
    get: () => columns.value.positions,
    set: (value) => { columns.value.positions = value; }
});
const getPosition = (header: Header): number =>
{
    return header.key in positions.value ? positions.value[header.key] : header.position;
};

provide('loaded', loaded);
provide('layout', layout);
provide('loading-label', props.loadingLabel);
provide('empty-label', props.emptyLabel);
provide('row-click', props.rowClick);
provide('row-class', props.rowClass);
provide('draggable', props.draggable);
provide('onDrag', (items: any[]) => emit('drag', items));
provide('scrollBar', scrollBar);

const onSorting = (header: any): void =>
{
    const sort = header.sort || '';

    if (pager.value.sorting === sort)
    {
        pager.value.order = pager.value.order === 'ASC' ? 'DESC' : 'ASC';
    }
    else
    {
        pager.value.sort(header.sort || '');
    }

    emit('change');
};

const checkItems = (value: boolean): void =>
{
    emit('check', value);
};

const onScrollBar = (e: Event) =>
{
    const left = (e.target as HTMLDivElement).scrollLeft;

    if (!scrollableContent.value?.setScrollLeft) return;

    scrollableContent.value.setScrollLeft(left);
};

watch(() => scrollableContent.value?.width, (newValue) =>
{
    if (newValue == null || !scrollBar.value) return;

    scrollBar.value.style.width = `${newValue}px`;
}, {
    flush: 'post'
});
</script>

<template>
    <Dropdown :headers="headers" v-model:visible="visible" v-model:positions="positions" v-if="showHeader && props.customisable" />
    <div ref="content">
        <component :is="layoutName" v-bind="$attrs" :loading="loading" :pager="pager" :headers="headers" :rows="items" :show-header="showHeader" ref="scrollableContent" @change="onSorting" @check="checkItems">
            <template #row="{item, index}: any">
                <slot name="row" :item="item" :index="index"></slot>
            </template>
        </component>
    </div>
    <portal to="data-card-footer">
        <div
            v-show="isScrollBar"
            class="scroll scroll-x scroll-container mb-2"
            @scroll="onScrollBar"
        >
            <div class="scrollbar" ref="scrollBar"></div>
        </div>
    </portal>
</template>

<style lang="scss" scoped>
.scrollbar {
    height: 6px;
}

.scroll-container {
    height: 6px;
    overflow-y: hidden;
}
</style>
