<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { useAuth } from '@/plugins/auth';
import { useAlerts } from '@/plugins/alerts';
import { useLocalization } from '@/plugins/localization';

declare const OneSignal: any;

defineOptions({
    name: 'PushNotifications'
});

const { $auth } = useAuth();
const { $alert } = useAlerts();
const { $t } = useLocalization();

const userIdentity = computed(() => $auth.user());

function subscribe(): void
{
    if (!OneSignal.isPushNotificationsSupported())
    {
        $alert.warning($t('[[[Przeglądarka z której korzystasz nie wspiera powiadomień PUSH]]]'));

        return;
    }

    OneSignal.showNativePrompt();
}

onMounted(() =>
{
    OneSignal.push(() =>
    {
        OneSignal.init({
            appId: import.meta.env.VITE_APP_ONESIGNAL,
            allowLocalhostAsSecureOrigin: !import.meta.env.PROD
        });

        OneSignal.on('subscriptionChange', (isSubscribed: boolean) =>
        {
            if (isSubscribed === true && userIdentity.value)
            {
                OneSignal.sendTags({
                    subscriberId: userIdentity.value.publicId
                });
            }
        });
    });

    subscribe();
});
</script>
