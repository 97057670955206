/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import EmptyLayout from '@/views/layouts/EmptyLayout.vue';
import Dashboard from '@/modules/core/home/views/Dashboard.vue';
import Filemanager from '@/modules/core/files/views/MainView.vue';

export default [
    {
        path: 'dashboard',
        name: 'dashboard',
        meta: { auth: true },
        component: Dashboard
    },
    {
        // Konto użytkownika
        path: 'account',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            {
                path: 'profile',
                name: 'core-account-profile',
                meta: { auth: true },
                component: () => import('@/modules/core/account/views/Profile.vue')
            },
            {
                path: 'password',
                name: 'core-account-password',
                meta: { auth: true, stop: true },
                component: () => import('@/modules/core/account/views/Password.vue')
            },
            {
                path: 'notifications',
                name: 'core-account-notifications',
                meta: { auth: true },
                component: () => import('@/modules/core/account/views/Notifications.vue')
            },
            {
                path: 'security',
                name: 'core-account-security',
                meta: { auth: true },
                component: () => import('@/modules/core/account/views/Security.vue')
            },
            {
                path: 'pats',
                name: 'core-pats',
                meta: { auth: true },
                component: () => import('@/modules/core/pat/views/PatView.vue')
            },
            // Dashboardy
            {
                path: 'dashboards/add',
                name: 'core-dashboards-add',
                meta: { auth: true },
                component: () => import('@/modules/core/dashboards/views/DashboardForm.vue')
            },
            {
                path: 'dashboards/:id(\\d+)',
                name: 'core-dashboards-edit',
                meta: { auth: true, model: 'Ideo.Core.Data.Model.CoreDashboard' },
                component: () => import('@/modules/core/dashboards/views/DashboardForm.vue')
            },
            {
                path: 'dashboards/:id(\\d+)/arrangement',
                name: 'core-dashboards-arrangement',
                meta: { auth: true, model: 'Ideo.Core.Data.Model.CoreDashboard' },
                props: true,
                component: Dashboard
            },
            {
                path: 'dashboards/:filterName(\\w+)?/:filterValue(\\d+)?',
                name: 'core-dashboards',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/dashboards/views/DashboardsList.vue')
            }
        ]
    },
    {
        path: 'admin',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            // Użytkownicy
            {
                path: 'users/add',
                name: 'core-users-add',
                meta: { auth: true },
                component: () => import('@/modules/core/users/views/UserForm.vue')
            },
            {
                path: 'users/:id(\\d+)',
                name: 'core-users-edit',
                meta: { auth: true, model: 'Ideo.Core.Data.Model.CoreUser' },
                component: () => import('@/modules/core/users/views/UserForm.vue')
            },
            {
                path: 'users/:filterName(\\w+)?/:filterValue(\\d+)?',
                name: 'core-users',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/users/views/UsersList.vue')
            },
            // Role
            {
                path: 'roles',
                name: 'core-roles',
                meta: { auth: true },
                component: () => import('@/modules/core/roles/views/RolesList.vue')
            },
            {
                path: 'roles/add',
                name: 'core-roles-add',
                meta: { auth: true },
                component: () => import('@/modules/core/roles/views/RoleForm.vue')
            },
            {
                path: 'roles/:id(\\d+)',
                name: 'core-roles-edit',
                meta: { auth: true, model: 'Ideo.Core.Data.Model.CoreRole' },
                component: () => import('@/modules/core/roles/views/RoleForm.vue')
            },
            // Zespoły
            {
                path: 'teams',
                name: 'core-teams',
                meta: { auth: true },
                component: () => import('@/modules/core/teams/views/TeamsList.vue')
            },
            {
                path: 'teams/add',
                name: 'core-teams-add',
                meta: { auth: true },
                component: () => import('@/modules/core/teams/views/TeamForm.vue')
            },
            {
                path: 'teams/:id(\\d+)',
                name: 'core-teams-edit',
                meta: { auth: true, model: 'Ideo.Core.Data.Model.CoreTeam' },
                component: () => import('@/modules/core/teams/views/TeamForm.vue')
            },
            // Organizacja
            {
                path: 'units',
                name: 'core-units',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/organization/views/UnitForm.vue')
            },
            {
                path: 'units/add',
                name: 'core-units-add',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/organization/views/UnitForm.vue')
            },
            {
                path: 'units/:id(\\d+)',
                name: 'core-units-edit',
                meta: { auth: true, model: 'Ideo.Core.Data.Model.CoreOrganizationalUnit' },
                props: true,
                component: () => import('@/modules/core/organization/views/UnitForm.vue')
            },
            // Zastępstwa
            {
                path: 'substitutions/add',
                name: 'core-substitutions-add',
                meta: { auth: true },
                component: () => import('@/modules/core/substitutions/views/SubstitutionForm.vue')
            },
            {
                path: 'substitutions/:id(\\d+)',
                name: 'core-substitutions-edit',
                meta: { auth: true },
                component: () => import('@/modules/core/substitutions/views/SubstitutionForm.vue')
            },
            {
                path: 'substitutions/:filterName(\\w+)?/:filterValue(\\d+)?',
                name: 'core-substitutions',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/substitutions/views/SubstitutionList.vue')
            },
            {
                path: 'proxies',
                name: 'proxies-list',
                meta: { auth: true },
                component: () => import('@/modules/core/proxies/views/ProxiesList.vue')
            }
        ]
    },
    {
        path: 'settings',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            // Konta
            {
                path: 'accounts',
                name: 'core-accounts',
                meta: { auth: true },
                component: () => import('@/modules/core/settings/views/Accounts.vue')
            },
            // Bezpieczeństwo
            {
                path: 'security',
                name: 'core-security',
                meta: { auth: true },
                component: () => import('@/modules/core/settings/views/Security.vue')
            },
            // Lista zaufanych adresów
            {
                path: 'trusted-ips',
                name: 'core-trusted-ips',
                meta: { auth: true },
                component: () => import('@/modules/core/settings/views/TrustedIPs.vue')
            },
            // Recaptcha
            {
                path: 'recaptcha',
                name: 'core-recaptcha',
                meta: { auth: true },
                component: () => import('@/modules/core/settings/views/ReCaptcha.vue')
            },
            // Poczta
            {
                path: 'mailing',
                name: 'core-mailing',
                meta: { auth: true },
                component: () => import('@/modules/core/settings/views/Mailing.vue')
            },
            // Sms
            {
                path: 'sms',
                name: 'core-sms',
                meta: { auth: true },
                component: () => import('@/modules/core/settings/views/Sms.vue')
            },
            // Firebase
            {
                path: 'firebase',
                name: 'core-firebase',
                meta: { auth: true },
                component: () => import('@/modules/core/settings/views/Firebase.vue')
            },
            // Paleta kolorów
            {
                path: 'colors',
                name: 'core-colors',
                meta: { auth: true },
                component: () => import('@/modules/core/settings/views/Colors.vue')
            },
            // Tłumaczenia
            {
                path: 'translations',
                name: 'core-translations',
                meta: { auth: true },
                component: () => import('@/modules/core/settings/views/Translations.vue')
            },
            // Ldap
            {
                path: 'ldap',
                name: 'core-ldap',
                meta: { auth: true },
                component: () => import('@/modules/core/ldap/views/LdapSourcesList.vue'),
            },
            {
                path: 'add',
                props: true,
                name: 'core-ldap-add',
                meta: { auth: true },
                component: () => import('@/modules/core/ldap/views/LdapForm.vue')
            },
            {
                path: 'edit/:id',
                props: true,
                name: 'core-ldap-edit',
                meta: { auth: true },
                component: () => import('@/modules/core/ldap/views/LdapForm.vue')
            },
            // Powiadomienia
            {
                path: 'notifications/templates',
                name: 'core-notifications-templates',
                meta: { auth: true },
                component: () => import('@/modules/core/notifications/views/NotificationTemplatesList.vue')
            },
            {
                path: 'notifications/templates/add',
                name: 'core-notifications-templates-add',
                meta: { auth: true },
                component: () => import('@/modules/core/notifications/views/NotificationTemplateForm.vue')
            },
            {
                path: 'notifications/templates/edit/:id',
                name: 'core-notifications-templates-edit',
                meta: { auth: true },
                component: () => import('@/modules/core/notifications/views/NotificationTemplateForm.vue')
            },
            // Powiadomienia zbiorowe
            {
                path: 'notifications/collective',
                name: 'core-notifications-collective',
                meta: { auth: true },
                component: () => import('@/modules/core/collective-notifications/views/CollectiveNotificationTemplatesList.vue')
            },
            {
                path: 'notifications/collective/add',
                name: 'core-notifications-collective-add',
                meta: { auth: true },
                component: () => import('@/modules/core/collective-notifications/views/CollectiveNotificationTemplateForm.vue')
            },
            {
                path: 'notifications/collective/edit/:id',
                name: 'core-notifications-collective-edit',
                meta: { auth: true },
                component: () => import('@/modules/core/collective-notifications/views/CollectiveNotificationTemplateForm.vue')
            },
            // Monitoring
            {
                path: 'monitoring',
                name: 'core-monitoring',
                meta: { auth: true },
                component: () => import('@/modules/core/settings/views/Monitoring.vue')
            },
            // Logs
            {
                path: 'logs',
                name: 'core-logs',
                meta: { auth: true },
                component: () => import('@/modules/core/logs/views/Switches.vue'),
            }
        ]
    },
    {
        path: 'tools',
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            // Wyszukiwarka
            {
                path: 'search/indices',
                name: 'search-indices',
                meta: { auth: true },
                component: () => import('@/modules/core/search/views/IndicesList.vue')
            },
            {
                path: 'search/stopwords',
                name: 'search-stopwords',
                meta: { auth: true },
                component: () => import('@/modules/core/search/views/StopWordsList.vue')
            },
            {
                path: 'search/synonyms',
                name: 'search-synonyms',
                meta: { auth: true },
                component: () => import('@/modules/core/search/views/SynonymsList.vue')
            },
            // Historia logowania
            {
                path: 'monitoring/authlog',
                name: 'core-authlog',
                meta: { auth: true },
                component: () => import('@/modules/core/authlog/views/AuthlogList.vue')
            },
            // Rejestr zmian
            {
                path: 'monitoring/changelog/:sessionId(\\d+)?',
                name: 'core-changelog',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/changelog/views/ChangesList.vue')
            },
            {
                path: 'monitoring/changelog/diff/:id(\\d+)?',
                name: 'core-changelog-diff',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/changelog/views/ChangesView.vue')
            },
            // Harmonogram zadań
            {
                path: 'monitoring/scheduler',
                name: 'core-scheduler',
                meta: { auth: true },
                component: () => import('@/modules/core/scheduler/views/JobsList.vue')
            },
            {
                path: 'monitoring/scheduler/history/:type',
                name: 'core-scheduler-history',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/scheduler/views/JobHistory.vue')
            },
            {
                path: 'monitoring/scheduler/details/:id',
                name: 'core-scheduler-details',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/scheduler/views/JobDetails.vue')
            },
            // Monitor kolejki zadań
            {
                path: 'monitoring/queues',
                name: 'core-queues',
                meta: { auth: true },
                component: () => import('@/modules/core/queues/views/MessageHistory.vue')
            },
            {
                path: 'monitoring/queues/details/:id',
                name: 'core-queues-details',
                meta: { auth: true },
                component: () => import('@/modules/core/queues/views/MessageDetails.vue')
            }
        ]
    },
    {
        path: 'filemanager',
        name: 'filemanager',
        meta: { auth: true },
        component: Filemanager
    },
    {
        path: 'imports',
        name: 'imports',
        meta: { auth: true },
        redirect: '/error/404',
        component: EmptyLayout,
        children: [
            // Konta
            {
                path: 'importsList',
                name: 'imports-list',
                meta: { auth: true },
                props: true,
                component: () => import('@/modules/core/imports/view/ImportsList.vue')
            },
            {
                path: 'importsConfiguration',
                name: 'imports-configuration',
                meta: { auth: true },
                component: () => import('@/modules/core/imports/view/ConfigurationImportsList.vue')
            },
        ]
    },
    {
        path: 'products',
        name: 'products-list',
        meta: { auth: true },
        props: true,
        component: () => import('@/modules/core/products/views/ProductsList.vue'),
    },
    {
        path: 'details/:id',
        name: 'product-details',
        meta: { auth: true },
        props: true,
        component: () => import('@/modules/core/products/views/ProductDetails.vue')
    },
    {
        path: 'shops-list',
        name: 'shops-list',
        meta: { auth: true },
        component: () => import('@/modules/core/shops/views/ShopsList.vue')
    },
    {
        path: 'group-configuration',
        name: 'group-configuration',
        meta: { auth: true },
        component: () => import('@/modules/core/groups/views/GroupList.vue')
    },
    {
        path: 'category-structure',
        name: 'category-structure',
        meta: { auth: true },
        component: () => import('@/modules/core/categories/view/CategoriesStructure.vue')
    },
    {
        path: 'reports',
        name: 'reports',
        meta: { auth: true },
        component: () => import('@/modules/core/reports/views/ReportsList.vue')
    },
    {
        path: 'compare-prices',
        name: 'compare-prices',
        meta: { auth: true },
        component: () => import('@/modules/core/compare-prices/views/ComparePrices.vue')
    },
    {
        path: 'trash',
        name: 'trash',
        meta: { auth: true },
        component: () => import('@/modules/core/trash/views/TrashList.vue')
    },
];
