<template>
    <component :is="tagName" class="pointer text-break text-link" :class="classNames" @click="onClick" v-if="visible">
        <slot name="default" v-if="loaded"></slot>
        <placeholder :width="[minWidth, maxWidth]" :height="14" v-else></placeholder>
    </component>
</template>

<script lang="ts" setup>
import { computed, inject, useAttrs, type Ref, type ComputedRef } from 'vue';
import { Header } from '../helpers';

export interface Props
{
    index: number;
    header: Header;
}

defineOptions({
    name: 'list-view-header',
    inheritAttrs: false
});

const props = withDefaults(defineProps<Props>(), {});
const loaded = inject<Ref<boolean>>('loaded');
const layout = inject<Ref<string>>('layout');
const item = inject<ComputedRef<any>>('item');
const rowClick = inject<(item: any, index: number, e: any) => void>('row-click');

const table = computed(() => layout.value == 'table');
const tagName = computed(() => table.value ? 'td' : 'h5');
const classNames = computed(() => table.value ? '' : 'mb-0');
const visible = computed(() => props.header.visible);

const $attrs = useAttrs();
const minWidth = computed(() => parseInt($attrs.width as string) || 100);
const maxWidth = computed(() => parseInt($attrs.width as string) || 175);

const onClick = (e: any): void =>
{
    if (rowClick)
    {
        rowClick(item.value, props.index, e);
    }
};
</script>
