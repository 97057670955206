<template>
    <div class="dashboard-tile">
        <div class="dashboard-tile__header scroll light">
            <div class="title">{{ $t('[[[Importy]]]') }}</div>
        </div>
        <div class="dashboard-tile__body scroll light">
            <div class="value">{{ itemValue !== null ? itemValue : 'N/A' }}</div>
        </div>
        <div class="dashboard-tile__footer scroll light">
            <button type="button" class="btn btn-info" :title="$t('[[[Zobacz]]]')" @click="$router.push({name:'imports-list'})">
                <i class="fas fa-caret-square-right"></i>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import HomeService from '@/modules/core/home/services/HomeService';

export default class ImportsTile extends Vue
{
    private loading: boolean = false;
    private itemValue: number = null;

    public async created(): Promise<void>
    {
        this.loadData();
    }

    private async loadData(): Promise<void>
    {
        this.loading = true;

        try
        {
            const response = await HomeService.getAllImports();

            this.itemValue = response;
        }
        catch (ex)
        {
            this.handleException(ex, {
                0: (ex: any) => this.$alert.warning(this.$t(ex.message)),
                400: (ex: any) => this.$alert.warning(this.$t(ex.message))
            });
        }

        this.loading = false;
    }
}
</script>

