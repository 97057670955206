export default {
    items: [
        {
            name: '[[[Pulpit]]]',
            route: 'dashboard',
            icon: 'fas fa-fw fa-home',
            children: [
                {
                    name: '[[[Profil użytkownika]]]',
                    route: 'core-account-profile',
                    icon: 'fa fa-fw fa-user-circle'
                },
                {
                    name: '[[[Zmiana hasła]]]',
                    route: 'core-account-password',
                    icon: 'fa fa-fw fa-toggle-on'
                },
                {
                    name: '[[[Powiadomienia]]]',
                    route: 'core-account-notifications',
                    icon: 'fas fa-fw fa-envelope-open'
                },
                {
                    name: '[[[Bezpieczeństwo]]]',
                    route: 'core-account-security',
                    icon: 'fas fa-fw fa-phone-laptop'
                },
                {
                    name: '[[[Indywidualne tokeny]]]',
                    route: 'core-pats',
                    icon: 'fas fa-fw fa-key'
                }
            ]
        },
        {
            name: '[[[Usługi]]]',
            icon: 'fas fa-fw fa-server',
            namespace: 'Ideo.Core.Common.Application.Permissions.Saas',
            children: [
                {
                    name: '[[[Klienci]]]',
                    route: 'saas-tenants',
                    icon: 'fas fa-fw fa-users-class',
                    auth: { all: ['TenantsPermissions@View'], any: [] },
                    children: [
                        {
                            name: '[[[Dodanie klienta]]]',
                            route: 'saas-tenants-add',
                            auth: { all: ['TenantsPermissions@Edit'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Edycja klienta]]]',
                            route: 'saas-tenants-edit',
                            auth: { all: ['TenantsPermissions@Edit'], any: [] },
                            visible: false
                        }
                    ]
                },
                {
                    name: '[[[Bazy danych]]]',
                    route: 'saas-databases',
                    icon: 'fas fa-fw fa-database',
                    auth: { all: ['DatabasesPermissions@View'], any: [] },
                    children: [
                        {
                            name: '[[[Dodanie serwera]]]',
                            route: 'saas-databases-add',
                            auth: { all: ['DatabasesPermissions@Edit'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Edycja serwera]]]',
                            route: 'saas-databases-edit',
                            auth: { all: ['DatabasesPermissions@Edit'], any: [] },
                            visible: false
                        }
                    ]
                },
                {
                    name: '[[[Serwery plików]]]',
                    route: 'saas-storages',
                    icon: 'fas fa-fw fa-hdd',
                    auth: { all: ['StoragesPermissions@View'], any: [] },
                    children: [
                        {
                            name: '[[[Dodanie serwera]]]',
                            route: 'saas-storages-add',
                            auth: { all: ['StoragesPermissions@Edit'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Edycja serwera]]]',
                            route: 'saas-storages-edit',
                            auth: { all: ['StoragesPermissions@Edit'], any: [] },
                            visible: false
                        }
                    ]
                }
            ]
        },
        {
            name: '[[[Scrapper]]]',
            icon: 'fas fa-browser',
            children: [
                {
                    name: '[[[Towary]]]',
                    route: 'products-list',
                    icon: 'fa fa-cube',
                    namespace: 'Ideo.Core.Workspace.Application.Permissions',
                    auth: { all: ['ProductsPermissions@ViewProducts'], any: [] },
                    children: [
                        {
                            name: '[[[Szczegóły]]]',
                            route: 'product-details',
                            visible: false
                        }
                    ]
                },
                {
                    name: '[[[Grupy]]]',
                    icon: '	fa fa-cubes',
                    route: 'group-configuration',
                    namespace: 'Ideo.Core.Workspace.Application.Permissions',
                    auth: { all: ['GroupsPermissions@ViewGroups'], any: [] }
                },
                {
                    name: '[[[Struktura kategorii]]]',
                    route: 'category-structure',
                    icon: 'fa fa-sitemap',
                    namespace: 'Ideo.Core.Workspace.Application.Permissions',
                    auth: { all: ['CategoriesPermissions@ViewCategories'], any: [] }
                },
                {
                    name: '[[[Listy importów]]]',
                    route: 'imports-list',
                    icon: 'fa fa-tasks',
                    visible: true,
                    auth: { all: ['ImportsPermissions@ViewImports'], any: [] },
                },
                {
                    name: '[[[Konfiguracja importów]]]',
                    route: 'imports-configuration',
                    icon: 'fa fa-cogs',
                    visible: true,
                    auth: { all: ['ImportsPermissions@ViewImports'], any: [] },
                }
            ]
        },
        {
            name: '[[[Raporty]]]',
            icon: 'fas fa-chart-pie',
            children: [
                {
                    name: '[[[Raport cen i dostępności]]]',
                    route: 'reports',
                    icon: 'fa fa-bar-chart',
                    namespace: 'Ideo.Core.Workspace.Application.Permissions',
                    auth: { all: ['ReportsPermissions@ViewReports'], any: [] }
                },
                {
                    name: '[[[Zestawienie cen konkurencji]]]',
                    route: 'compare-prices',
                    icon: 'far fa-not-equal',
                    namespace: 'Ideo.Core.Workspace.Application.Permissions',
                    auth: { all: ['ReportsPermissions@ViewReports'], any: [] }
                }
            ]
        },
        {
            name: '[[[Panel administracyjny]]]',
            icon: 'fas fa-fw fa-desktop',
            namespace: 'Ideo.Core.Common.Application.Permissions.Core',
            children: [
                {
                    name: '[[[Lista wszystkich sklepów]]]',
                    route: 'shops-list',
                    namespace: 'Ideo.Core.Workspace.Application.Permissions',
                    auth: { all: ['ShopsPermissions@ViewShops'], any: [] },
                    icon: 'fa fa-fw fa-user'
                },
                {
                    name: '[[[Użytkownicy]]]',
                    route: 'core-users',
                    icon: 'fa fa-fw fa-user',
                    auth: { all: ['UsersPermissions@View'], any: [] },
                    children: [
                        {
                            name: '[[[Dodanie użytkownika]]]',
                            route: 'core-users-add',
                            auth: { all: ['UsersPermissions@Edit'], any: [] },
                            visible: false
                        },
                        {
                            name: '[[[Edycja użytkownika]]]',
                            route: 'core-users-edit',
                            auth: { all: ['UsersPermissions@Edit'], any: [] },
                            visible: false
                        }
                    ]
                },
                {
                    name: '[[[Zarządzanie aplikacją]]]',
                    icon: 'fas fa-fw fa-cogs',
                    children: [
                        {
                            name: '[[[Konfiguracja proxy]]]',
                            route: 'proxies-list',
                            icon: 'fas fa-fw fa-shield-alt',
                            namespace: 'Ideo.Core.Workspace.Application.Permissions',
                            auth: { all: ['ProxiesPermissions@ViewProxies'], any: [] }
                        },
                        {
                            name: '[[[Role i uprawnienia]]]',
                            route: 'core-roles',
                            icon: 'fa fa-fw fa-users',
                            auth: { all: ['RolesPermissions@View'], any: [] },
                            children: [
                                {
                                    name: '[[[Dodanie roli]]]',
                                    route: 'core-roles-add',
                                    auth: { all: ['RolesPermissions@Edit'], any: [] },
                                    visible: false
                                },
                                {
                                    name: '[[[Edycja roli]]]',
                                    route: 'core-roles-edit',
                                    auth: { all: ['RolesPermissions@Edit'], any: [] },
                                    visible: false
                                }
                            ]
                        },
                        {
                            name: '[[[Harmonogram zadań]]]',
                            route: 'core-scheduler',
                            icon: 'far fa-fw fa-clock',
                            children: [
                                {
                                    name: '[[[Historia zadania]]]',
                                    route: 'core-scheduler-history',
                                    visible: false
                                },
                                {
                                    name: '[[[Szczegóły zadania]]]',
                                    route: 'core-scheduler-details',
                                    visible: false
                                }
                            ]
                        },
                    ]
                }
            ]
        }
    ]
};
