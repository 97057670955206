<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
import { useRoute } from 'vue-router';
import Loader from '@/components/common/Loader.vue';
import CustomToast from './CustomToast.vue';
import axios from 'axios';
import { useThemeStore } from '@/store/theme';
import { useEvents } from '@/plugins/events';
import { useAlerts } from '@/plugins/alerts';
import { useLocalization } from '@/plugins/localization';
import { useMixins } from '@/plugins/mixins';

const appHash = ref<string>('');
const frequency = ref<number>(2 * 60 * 1000);
const exceptionErrors = ref<string[]>([]);

const errorsModalRef = ref(null);
const route = useRoute();
const { $events } = useEvents();
const { $alert } = useAlerts();
const { $t } = useLocalization();
const { $handleException } = useMixins();

onMounted(() =>
{
    $events.$on('errors-modal', toggleErrorsModal);
});

onBeforeUnmount(async () =>
{
    if (import.meta.env.PROD)
    {
        await checkVersion(`${location.origin}/manifest.webmanifest`);
    }

    await useThemeStore().initColors();

    $events.$off('errors-modal', toggleErrorsModal);
});

async function checkVersion(url: string): Promise<void>
{
    appHash.value = await loadManifest(url);

    setInterval(async () =>
    {
        const result = await loadManifest(url);

        if (result)
        {
            appHash.value = result;
        }
    }, frequency.value);
}

async function loadManifest(url: string): Promise<string | null>
{
    try
    {
        const time = new Date();

        time.setSeconds(0);
        time.setMilliseconds(0);

        const response = await axios.get(`${url}?t=${time.getTime()}`);

        return response.data.hash;
    }
    catch (ex)
    {
        $handleException(ex, {
            0: (ex: any) => $alert.danger(ex.message)
        });

        return null;
    }
}

function toggleErrorsModal(ex: any): void
{
    const errors = Object.values(ex ?? {}).flat();

    exceptionErrors.value = [...new Set(errors)] as string[];

    if (errors.length > 0 && !['login', 'forgot-password'].includes((route?.name || '') as string))
    {
        errorsModalRef.value?.show();
        $events.$emit('error-occured');
    }
}

function getErrorMessage(error: string): string
{
    return error.startsWith('[[[') ? $t(error) : error;
}

watch(appHash, (value, old) =>
{
    if (value !== old && old)
    {
        const content = {
            component: CustomToast,
            props: {
                message: $t('[[[Dostępna jest nowa aktualizacja. Proszę zapisać postęp pracy i klinąć przycisk "Aktualizuj". Możesz również zaakceptować aktualizację poprzez odświeżenie okna przeglądarki. Brak aktualizacji może spowodować błędy.]]]'),
                update: $t('[[[Aktualizuj]]]'),
            },
            listeners: {
                reload: () =>
                {
                    location.reload();
                }
            }
        };

        $alert.show(content, {
            type: "error",
            timeout: false,
            closeButton: false,
            closeOnClick: false,
            draggable: false
        });
    }
});
</script>

<template>
    <div v-if="!$auth.ready()">
        <loader></loader>
    </div>
    <router-view v-else />
    <vue-progress-bar></vue-progress-bar>

    <ideo-modal ref="errorsModalRef" :title="$t('[[[Wystąpiły błędy podczas zapisywania formularza]]]')" hide-footer static>
        <div v-for="(error, index) in exceptionErrors" :key="index" class="text-danger" :class="{'mb-2': index < exceptionErrors.length - 1}">
            <i class="fa-solid fa-fw fa-triangle-exclamation"></i>
            {{ getErrorMessage(error) }}
        </div>

        <div class="text-end">
            <action-button
                :text="$t('[[[Zamknij]]]')"
                variant="outline-dark"
                icon="far fa-xmark"
                @click="errorsModalRef.hide()"
            />
        </div>
    </ideo-modal>
</template>

<style lang="scss">
    // Import Main styles for this application
    @import "@/assets/scss/style";
</style>
